<template>
  <div>
    <div class="hcpBox" v-if="isKakao === 'N'">hcp 인증이 필요한 컨텐츠 입니다.</div>
    <div v-else>
      <!-- card 추가방법
      0: comp에 card 추가
      1: router 에 추가 경로확인
      2: 주석 -> 추가 코드
      -->

      <!-- 친구카드 tes01 -->
      <FriendsCardPageTest01
        ref="refFriendsCardPageTest01"
        v-if="$route.path === '/friends/card1'"
        @slideChange="slideChange"
      />

      <!-- 0314 하루날디 웨비나 카드뉴스 (채널톡 발송용) -->
      <AstellasWebinar03CardPage
        ref="refAstellasWebinar03"
        v-if="$route.path === '/friends/AstellasWebinar03'"
        @slideChange="slideChange"
      />

      <!-- 1212 하루날디 웨비나 카드뉴스 (채널톡 발송용) -->
      <AstellasWebinar1212CardPage
          ref="refAstellasWebinar1212"
          v-if="$route.path === '/friends/AstellasWebinar1212'"
          @slideChange="slideChange"
      />

      <!-- 0330 하루날디 웨비나 카드뉴스 (채널톡 발송용) -->
      <AstellasWebinar0330CardPage
        ref="refAstellasWebinar03"
        v-if="$route.path === '/friends/AstellasWebinar0330'"
        @slideChange="slideChange"
      />

      <!-- 03-04 베타미가 심포지움 카드뉴스 (채널톡 발송용) -->
      <AstellasBetmigaSympo04CardPage
        ref="refAstellasBetmigaSympo04"
        v-if="$route.path === '/friends/AstellasBetmigaSympo04'"
        @slideChange="slideChange"
      />

      <!-- ESLA 행사 연기 안내 카드뉴스 -->
      <AstellasESLA04CardPage
        ref="refAstellasESLA04"
        v-if="$route.path === '/friends/AstellasESLA04'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 TX 1차 카드뉴스 -->
      <PrografTx_1stCardPage
        ref="refPrografTx_1st"
        v-if="$route.path === '/friends/PrografTx_1st'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Tx 뉴스 클리핑 04월  -->
      <PrografTx_NewsClipping_April_CardPage
        ref="refPrografTx_NewsClipping_April"
        v-if="$route.path === '/card/PrografTx_NewsClipping_April'"
        @slideChange="slideChange"
      />

      <!-- 0411 하루날디 웨비나 카드뉴스 (채널톡 발송용) -->
      <AstellasWebinar0411CardPage
        ref="refAstellasWebinar03"
        v-if="$route.path === '/friends/AstellasWebinar0411'"
        @slideChange="slideChange"
      />

      <!-- 2024 대한비뇨의학회 학술행사 (04-07) (채널톡 발송용) -->
      <HarnaldSympo04CardPage
        ref="refHarnaldSympo04"
        v-if="$route.path === '/friends/HarnaldSympo04'"
        @slideChange="slideChange"
      />

      <!-- 2024 KCR (05) -->
      <PrografAi_KCR202405CardPage
        ref="refPrografAi_KCR202405"
        v-if="$route.path === '/friends/PrografAi_KCR2024'"
        @slideChange="slideChange"
      />

      <!-- Urology Webinar (05) -->
      <HarnaldUrology05CardPage
        ref="refHarnaldUrology055"
        v-if="$route.path === '/friends/HarnaldUrology05'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Tx 2차 카드뉴스 (신이식) -->
      <PrografTx_2nd_kidneyCardPage
        ref="refPrografTx_2nd_kidney"
        v-if="$route.path === '/friends/PrografTx_2nd_kidney'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Tx 2차 카드뉴스 (간이식) -->
      <PrografTx_2nd_liverCardPage
        ref="refPrografTx_2nd_liver"
        v-if="$route.path === '/friends/PrografTx_2nd_liver'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Ai 동행 카드뉴스 (0503) -->
      <PrografAi_DHCardPage
        ref="refPrografAi_DH"
        v-if="$route.path === '/friends/PrografAi_DH'"
        @slideChange="slideChange"
      />

      <!-- Harnald Card News (05) (채널톡 발송용) -->
      <Harnald_News05CardPage
        ref="refHarnald_News05"
        v-if="$route.path === '/friends/Harnald_News05'"
        @slideChange="slideChange"
      />

      <!-- 2024 대한비뇨의학회 학술행사 (05-08) (채널톡 발송용) -->
      <HarnaldSympo05CardPage
        ref="refHarnaldSympo05"
        v-if="$route.path === '/friends/HarnaldSympo05'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Ai 동행 카드뉴스 (Tacrolimus) (채널톡 발송용) -->
      <PrografAi_DH_TacrolimusCardPage
        ref="refPrografAi_DH_Tacrolimus"
        v-if="$route.path === '/friends/PrografAi_DH_Tacrolimus'"
        @slideChange="slideChange"
      />

      <!-- 0613 하루날디 웨비나 초청장 (채널톡 발송용) -->
      <AstellasWebinar0613CardPage
        ref="refAstellasWebinar0613"
        v-if="$route.path === '/friends/AstellasWebinar0613'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Tx 3차 카드뉴스 (채널톡 발송용) -->
      <PrografTx_3rdCardPage
        ref="refPrografTx_3rd"
        v-if="$route.path === '/friends/PrografTx_3rd'"
        @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf (AI) 동행 캠페인 TAC concert (채널톡 발송용) -->
      <PrografAi_DH_TAC_ConnectCardPage
        ref="refPrografAi_DH_TAC_Connect"
        v-if="$route.path === '/friends/PrografAi_DH_TAC_Connect'"
        @slideChange="slideChange"
      />

      <!-- 프로그랍 Tx 힐링캠프 웨비나 초청장 (채널톡 발송용) -->
      <PrografTx_HealingCampCardPage
        ref="refPrografTx_HealingCamp"
        v-if="$route.path === '/friends/PrografTx_HealingCamp'"
        @slideChange="slideChange"
      />

      <!-- 24년 6월 베타미가 카드뉴스 (채널톡 발송용) -->
      <AstellasBetmiga06CardPage
        ref="refAstellasBetmiga06"
        v-if="$route.path === '/friends/AstellasBetmiga06'"
        @slideChange="slideChange"
      />

      <!-- 0716 하루날디 웨비나 초청장 (채널톡 발송용) -->
      <AstellasWebinar0716CardPage
        ref="refAstellasWebinar0716"
        v-if="$route.path === '/friends/AstellasWebinar0716'"
        @slideChange="slideChange"
      />

      <!-- 1129 하루날디 웨비나 초청장 (채널톡 발송용) -->
      <AstellasWebinar1029CardPage
          ref="refAstellasWebinar1029"
          v-if="$route.path === '/friends/AstellasWebinar1029'"
          @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf Ai_Japanese PMS data (채널톡 발송용) -->
      <PrografAi_Japanese_PMSCardPage
        ref="refPrografAi_Japanese_PMS"
        v-if="$route.path === '/friends/PrografAi_Japanese_PMS'"
        @slideChange="slideChange"
      />

      <!-- Harnald Card News 07월 (채널톡 발송용) -->
      <Harnald_News07CardPage
        ref="refHarnald_News07"
        v-if="$route.path === '/friends/Harnald_News07'"
        @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf Ai_Long-Term Safety Data (채널톡 발송용) -->
      <PrografAi_LongTermCardPage
        ref="refPrografAi_LongTermCard"
        v-if="$route.path === '/friends/PrografAi_LongTermCard'"
        @slideChange="slideChange"
      />
      
      <!-- 아스텔라스 Prograf TX_Transplant Expert Summit (채널톡 발송용) -->
      <PrografTx_TransplantExpertSummitCardPage
        ref="refPrografTx_TransplantExpertSummit"
        v-if="$route.path === '/friends/PrografTx_TransplantExpertSummit'"
        @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf TX_Transplant Lucky For NewLife (채널톡 발송용) -->
      <PrografTx_TransplantLuckyForNewLifeCardPage
          ref="refPrografTx_TransplantLuckyForNewLife"
          v-if="$route.path === '/friends/PrografTx_TransplantLuckyForNewLife'"
          @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf TX_Transplant Lucky For NewLife (채널톡 발송용) -->
      <PrografTx_TransplantLuckyForNewLifeResultCardPage
          ref="refPrografTx_TransplantLuckyForNewLifeResult"
          v-if="$route.path === '/friends/PrografTx_TransplantLuckyForNewLifeResult'"
          @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf TX_Transplant Lucky For NewLife (채널톡 발송용) -->
      <PrografTx_TransplantLuckyForNewLifeResultDSACardPage
          ref="refPrografTx_TransplantLuckyForNewLifeResultDSA"
          v-if="$route.path === '/friends/PrografTx_TransplantLuckyForNewLifeResultDSA'"
          @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf TX_INDUSTRY SYMPOSIUM (채널톡 발송용) -->
      <PrografTx_TransplantIndustrySymposiumCardPage
          ref="refPrografTx_TransplantIndustrySymposium"
          v-if="$route.path === '/friends/PrografTx_TransplantIndustrySymposium'"
          @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf Ai_TJC Improvement (채널톡 발송용) -->
      <PrografAi_TJC_ImprovementCardPage
        ref="refPrografAi_TJC_Improvement"
        v-if="$route.path === '/friends/PrografAi_TJC_Improvement'"
        @slideChange="slideChange"
      />


      <!-- 아스텔라스 Prograf Ai_ESLA Symposium (채널톡 발송용) -->
      <PrografAi_ESLASymposiumCardPage
        ref="refPrografAi_ESLASymposium"
        v-if="$route.path === '/friends/PrografAi_ESLASymposium'"
        @slideChange="slideChange"
      />

      <!-- 24년 7월 베타미가 카드뉴스 _ 웨비나 (채널톡 발송용) -->
      <AstellasBetmiga07CardPage
        ref="refAstellasBetmiga07"
        v-if="$route.path === '/friends/AstellasBetmiga07'"
        @slideChange="slideChange"
      />

      <!-- 24년 8월 베타미가 카드뉴스 (채널톡 발송용) -->
      <AstellasBetmiga08CardPage
        ref="refAstellasBetmiga08"
        v-if="$route.path === '/friends/AstellasBetmiga08'"
        @slideChange="slideChange"
      />

      <!-- 24년 8월 베타미가 카드뉴스 _ 학회일정 (채널톡 발송용) -->
      <AstellasBetmigaSympo08CardPage
        ref="refAstellasBetmigaSympo08"
        v-if="$route.path === '/friends/AstellasBetmigaSympo08'"
        @slideChange="slideChange"
      />

      <!-- 24년 8월 아스텔라스 Prograf TX_TES2024 (채널톡 발송용) -->
      <PrografTx_TES2024CardPage
        ref="refPrografTx_TES2024"
        v-if="$route.path === '/friends/PrografTx_TES2024'"
        @slideChange="slideChange"
      />

      <!-- 24년 8월 아스텔라스 하루날디 베타미가 웨비나 09월 (채널톡 발송용) -->
      <AstellasBetmiga09WebinarCardPage
        ref="refAstellasBetmiga09Webinar"
        v-if="$route.path === '/friends/AstellasBetmiga09Webinar'"
        @slideChange="slideChange"
      />

      <!-- 아스텔라스 Prograf Ai_Long-Term TRUST Safety Data (채널톡 발송용) -->
      <PrografAi_LongTermTrustCardPage
        ref="refPrografAi_LongTermTrustCard"
        v-if="$route.path === '/friends/PrografAi_LongTermTrustCard'"
        @slideChange="slideChange"
      />

      <!-- 24년 9월 베타미가 카드뉴스 _ 학회일정 (채널톡 발송용) -->
      <AstellasBetmigaSympo09CardPage
        ref="refAstellasBetmigaSympo09"
        v-if="$route.path === '/friends/AstellasBetmigaSympo09'"
        @slideChange="slideChange"
      />

      <!-- 24년 9월 베타미가 카드뉴스 _ 학회일정 (채널톡 발송용) -->
      <AstellasBetmigaSympo09CardPage
        ref="refAstellasBetmigaSympo09"
        v-if="$route.path === '/friends/AstellasBetmigaSympo09Temp'"
        @slideChange="slideChange"
      />

      <!-- 24년 9월 베타미가 카드뉴스 _ 학회일정 (채널톡 발송용) -->
      <AstellasBetmigaSympo10CardPage
          ref="refAstellasBetmigaSympo10"
          v-if="$route.path === '/friends/AstellasBetmigaSympo10'"
          @slideChange="slideChange"
      />

      <!-- 24년 09월 아스텔라스 Prograf Ai_Long-Term TRUST #2-1 Safety Data (채널톡 발송용) -->
      <PrografAi_LongTermTrust201CardPage
        ref="refPrografAi_LongTermTrust201Card"
        v-if="$route.path === '/friends/PrografAi_LongTermTrust201Card'"
        @slideChange="slideChange"
      />

      <!-- 24년 10월 아스텔라스 Prograf Ai_Ten Years TRUST (채널톡 발송용) -->
      <PrografAi_TenYearsTrustCardPage
          ref="refPrografAi_TenYearsTrustCard"
          v-if="$route.path === '/friends/PrografAi_TenYearsTrustCard'"
          @slideChange="slideChange"
      />

      <!-- 24년 09월 아스텔라스 Prograf (AI) 25th TAC concert (채널톡 발송용) -->
      <PrografAi_25th_TAC_ConcertCardPage
        ref="refPrografAi_25th_TAC_ConcertCard"
        v-if="$route.path === '/friends/PrografAi_25th_TAC_ConcertCard'"
        @slideChange="slideChange"
      />

      <!-- 24년 10월 아스텔라스 Prograf (AI) ESLA Symposium (채널톡 발송용) -->
      <PrografAi_ESLA_Sympo10CardPage
          ref="PrografAi_ESLA_Sympo10Card"
          v-if="$route.path === '/friends/PrografAi_ESLA_Sympo10Card'"
          @slideChange="slideChange"
      />

      <!-- 24년 09월 프로그랍 Tx 힐링캠프 웨비나 초청장 (채널톡 발송용) -->
      <PrografTx_2409HealingCampCardPage
        ref="refPrografTx_2409HealingCampCard"
        v-if="$route.path === '/friends/PrografTx_2409HealingCampCard'"
        @slideChange="slideChange"
      />

      <!-- 24년 9월 베타미가 카드뉴스 (채널톡 발송용) -->
      <AstellasBetmiga09CardPage
        ref="refAstellasBetmiga09"
        v-if="$route.path === '/friends/AstellasBetmiga09'"
        @slideChange="slideChange"
      />

      <AstellasBetmiga10CardPage
        ref="refAstellasBetmiga10"
        v-if="$route.path === '/friends/AstellasBetmiga10'"
        @slideChange="slideChange"
      />

      <!-- 추가 AI CardPage -->
      <!-- <CardPage ref="refAI_Card" v-if="$route.path === '/card'" @slideChange="slideChange" /> -->

      <!-- 추가 AI HanalDCard -->
      <!-- <HanalDCard ref="refAI_HanalDCard" v-if="$route.path === '/card/harnalD'" @slideChange="slideChange" /> -->

      <!-- 추가 AI Betamiga -->
      <!-- <Betamiga ref="refAI_Betamiga" v-if="$route.path === '/card/betamiga'" @slideChange="slideChange" /> -->

      <!-- 추가 TX PrografTX -->
      <!-- <PrografTX ref="ref_PrografTX" v-if="$route.path === '/card/prografTX'" @slideChange="slideChange" /> -->

      <!-- 추가 AI 23 -->
      <!-- <PrograAI23 ref="refAI_PrograAI23" v-if="$route.path === '/card/prografAI'" @slideChange="slideChange" /> -->

      <!-- 추가 AI RA콘텐츠 -->
      <!-- <RA_PrografAICardPage ref="refAI_RA" v-if="$route.path === '/card/prografAI_RA'" @slideChange="slideChange" /> -->

      <!-- 추가 AI LN콘텐츠 -->
      <!-- <LN_PrografAICardPage ref="refAI_LN" v-if="$route.path === '/card/prografAI_LN'" @slideChange="slideChange" /> -->

      <!-- 베타미가 학술대회 (06-12) 퀵메뉴 -->
      <!-- <AstellasBetmigaSympo07CardPage
        ref="refAstellasBetmigaSympo07"
        v-if="$route.path === '/card/AstellasBetmigaSympo07'"
        @slideChange="slideChange"
      /> -->

      <!-- 베타미가 컨텐츠 (24년 07월) 퀵메뉴 -->
      <!-- <Betamiga2407CardPage
        ref="refBetamiga2407"
        v-if="$route.path === '/card/Betamiga2407'"
        @slideChange="slideChange"
      /> -->

      <!-- 베타미가 컨텐츠 (24년 07월) 퀵메뉴 -->
      <!-- <HanalD4207CardPage ref="refHanalD4207" v-if="$route.path === '/card/HanalD4207'" @slideChange="slideChange" /> -->
    </div>

    <!-- 확인팝업 -->
    <!-- <confirmModal ref="refConfirmModal" :alertText="alertText" @completeInput="cell4_completeInputHandler" /> -->
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { mapActions, mapGetters } from 'vuex';

import FriendsCardPageTest01 from './friendsComp/FriendsCardPage.vue';
import AstellasWebinar03CardPage from './friendsComp/AstellasWebinar03CardPage.vue';
import AstellasWebinar1212CardPage from './friendsComp/AstellasWebinar1212CardPage.vue';
import AstellasWebinar0330CardPage from './friendsComp/AstellasWebinar0330CardPage.vue';
import AstellasBetmigaSympo04CardPage from './friendsComp/AstellasBetmigaSympo04CardPage.vue';
import AstellasESLA04CardPage from './friendsComp/AstellasESLA04CardPage.vue';
import PrografTx_NewsClipping_April_CardPage from './friendsComp/PrografTx_NewsClipping_April_CardPage.vue';
import AstellasWebinar0411CardPage from './friendsComp/AstellasWebinar0411CardPage.vue';
import HarnaldSympo04CardPage from './friendsComp/HarnaldSympo04CardPage.vue';
import Harnald_News05CardPage from './friendsComp/Harnald_News05CardPage.vue';
import HarnaldSympo05CardPage from './friendsComp/HarnaldSympo05CardPage.vue';
import PrografAi_DHCardPage from './friendsComp/PrografAi_DHCardPage.vue';

import confirmModal from '@/components/common/ConfirmModal.vue';
import PrografTx_1stCardPage from './friendsComp/PrografTx_1stCardPage.vue';
import PrografTx_2nd_liverCardPage from './friendsComp/PrografTx_2nd_liverCardPage.vue';
import PrografTx_2nd_kidneyCardPage from './friendsComp/PrografTx_2nd_kidneyCardPage.vue';
import PrografAi_KCR202405CardPage from './friendsComp/PrografAi_KCR202405CardPage.vue';
import HarnaldUrology05CardPage from './friendsComp/HarnaldUrology05CardPage.vue';
import PrografAi_DH_TacrolimusCardPage from './friendsComp/PrografAi_DH_TacrolimusCardPage.vue';
import AstellasWebinar0613CardPage from './friendsComp/AstellasWebinar0613CardPage.vue';
import PrografTx_3rdCardPage from './friendsComp/PrografTx_3rdCardPage.vue';
import PrografAi_DH_TAC_ConnectCardPage from './friendsComp/PrografAi_DH_TAC_ConnectCardPage.vue';
import PrografTx_HealingCampCardPage from './friendsComp/PrografTx_HealingCampCardPage.vue';
import AstellasBetmiga06CardPage from './friendsComp/AstellasBetmiga06CardPage.vue';
import AstellasWebinar0716CardPage from './friendsComp/AstellasWebinar0716CardPage.vue';
import AstellasWebinar1029CardPage from './friendsComp/AstellasWebinar1029CardPage.vue';
import PrografAi_Japanese_PMSCardPage from './friendsComp/PrografAi_Japanese_PMSCardPage.vue';
import Harnald_News07CardPage from './friendsComp/Harnald_News07CardPage.vue';
import PrografAi_LongTermCardPage from './friendsComp/PrografAi_LongTermCardPage.vue';
import PrografTx_TransplantExpertSummitCardPage from './friendsComp/PrografTx_TransplantExpertSummitCardPage.vue'
import PrografTx_TransplantLuckyForNewLifeCardPage from './friendsComp/PrografTx_TransplantLuckyForNewLifeCardPage.vue'
import PrografTx_TransplantLuckyForNewLifeResultCardPage from './friendsComp/PrografTx_TransplantLuckyForNewLifeResultCardPage.vue'
import PrografTx_TransplantLuckyForNewLifeResultDSACardPage from './friendsComp/PrografTx_TransplantLuckyForNewLifeResultDSACardPage.vue'
import PrografTx_TransplantIndustrySymposiumCardPage from './friendsComp/PrografTx_TransplantIndustrySymposiumCardPage.vue'
import PrografAi_TJC_ImprovementCardPage from './friendsComp/PrografAi_TJC_ImprovementCardPage.vue'
import PrografAi_ESLASymposiumCardPage from './friendsComp/PrografAi_ESLASymposiumCardPage.vue'
import AstellasBetmiga07CardPage from './friendsComp/AstellasBetmiga07CardPage.vue'
import AstellasBetmiga08CardPage from './friendsComp/AstellasBetmiga08CardPage.vue'
import AstellasBetmigaSympo08CardPage from './friendsComp/AstellasBetmigaSympo08CardPage.vue'
import PrografTx_TES2024CardPage from './friendsComp/PrografTx_TES2024CardPage.vue'
import AstellasBetmiga09WebinarCardPage from './friendsComp/AstellasBetmiga09WebinarCardPage.vue'
import PrografAi_LongTermTrustCardPage from './friendsComp/PrografAi_LongTermTrustCardPage.vue'
import AstellasBetmigaSympo09CardPage from './friendsComp/AstellasBetmigaSympo09CardPage.vue'
import AstellasBetmigaSympo10CardPage from './friendsComp/AstellasBetmigaSympo10CardPage.vue'
import PrografAi_LongTermTrust201CardPage from './friendsComp/PrografAi_LongTermTrust201CardPage.vue'
import PrografAi_25th_TAC_ConcertCardPage from './friendsComp/PrografAi_25th_TAC_ConcertCardPage.vue'
import PrografAi_ESLA_Sympo10CardPage from './friendsComp/PrografAi_ESLA_Sympo10CardPage.vue'
import PrografTx_2409HealingCampCardPage from './friendsComp/PrografTx_2409HealingCampCardPage.vue'
import AstellasBetmiga09CardPage from './friendsComp/AstellasBetmiga09CardPage.vue'
import AstellasBetmiga10CardPage from './friendsComp/AstellasBetmiga10CardPage.vue'
import PrografAi_TenYearsTrustCardPage from "@/components/card/friendsComp/PrografAi_TenYearsTrustCardPage.vue";


// import $ from 'jquery';
// import CardPage from './comp/CardPage.vue';
// import HanalDCard from './comp/HanalDCardPage.vue';
// import Betamiga from './comp/BetamigaCardPage.vue';
// import PrografTX from './comp/PrografTXCardPage.vue';
// import PrograAI23 from './comp/PrograAI23CardPage.vue';
// import RA_PrografAICardPage from './comp/RA_PrografAICardPage.vue';
// import LN_PrografAICardPage from './comp/LN_PrografAICardPage.vue';
// import AstellasBetmigaSympo07CardPage from './comp/AstellasBetmigaSympo07CardPage.vue';
// import Betamiga2407CardPage from './comp/Betamiga2407CardPage.vue';
// import HanalD4207CardPage from './comp/HanalD4207CardPage.vue';

export default {
  components: {
    swiper,
    swiperSlide,
    confirmModal,
    FriendsCardPageTest01,
    AstellasWebinar03CardPage,
    AstellasWebinar1212CardPage,
    AstellasWebinar0330CardPage,
    AstellasBetmigaSympo04CardPage,
    AstellasESLA04CardPage,
    PrografTx_1stCardPage,
    PrografTx_2nd_kidneyCardPage,
    PrografTx_2nd_liverCardPage,
    PrografTx_NewsClipping_April_CardPage,
    AstellasWebinar0411CardPage,
    HarnaldSympo04CardPage,
    PrografAi_KCR202405CardPage,
    HarnaldUrology05CardPage,
    PrografAi_DHCardPage,
    Harnald_News05CardPage,
    HarnaldSympo05CardPage,
    PrografAi_DH_TacrolimusCardPage,
    AstellasWebinar0613CardPage,
    PrografTx_3rdCardPage,
    PrografAi_DH_TAC_ConnectCardPage,
    PrografTx_HealingCampCardPage,
    AstellasBetmiga06CardPage,
    AstellasWebinar0716CardPage,
    AstellasWebinar1029CardPage,
    PrografAi_Japanese_PMSCardPage,
    Harnald_News07CardPage,
    PrografAi_LongTermCardPage,
    PrografTx_TransplantExpertSummitCardPage,
    PrografTx_TransplantLuckyForNewLifeCardPage,
    PrografTx_TransplantLuckyForNewLifeResultCardPage,
    PrografTx_TransplantLuckyForNewLifeResultDSACardPage,
    PrografTx_TransplantIndustrySymposiumCardPage,
    PrografAi_TJC_ImprovementCardPage,
    PrografAi_ESLASymposiumCardPage,
    AstellasBetmiga07CardPage,
    AstellasBetmiga08CardPage,
    AstellasBetmigaSympo08CardPage,
    PrografTx_TES2024CardPage,
    AstellasBetmiga09WebinarCardPage,
    PrografAi_LongTermTrustCardPage,
    AstellasBetmigaSympo09CardPage,
    AstellasBetmigaSympo10CardPage,
    PrografAi_LongTermTrust201CardPage,
    PrografAi_25th_TAC_ConcertCardPage,
    PrografAi_ESLA_Sympo10CardPage,
    PrografTx_2409HealingCampCardPage,
    AstellasBetmiga09CardPage,
    AstellasBetmiga10CardPage,
    PrografAi_TenYearsTrustCardPage,
    
    // CardPage,
    // HanalDCard,
    // Betamiga,
    // PrografTX,
    // PrograAI23,
    // RA_PrografAICardPage,
    // LN_PrografAICardPage,
    // AstellasBetmigaSympo07CardPage,
    // Betamiga2407CardPage,
    // HanalD4207CardPage,
  },
  data() {
    return {
      isKakao: 'N',
      // vendor: '',
      // userAgent: '',
      // alertText: '',
      // mobile4info: '',
    };
  },
  created() {
    // this.vendor = navigator.vendor;
    // this.userAgent = navigator.userAgent;

    // console.log('navigator', navigator);
    // console.log('isCertifyCard', this.isCertifyCard);
    // console.log('isKakao', this.isKakao);

    this.isKakao = 'N';
    if (this.isCertifyCard) {
      this.isKakao = 'Y';
      return;
    }

    // if (this.$route.path.includes('/friends/')) {
    //   if (!this.isCertifyCard) {
    //     this.isKakao = 'N';
    //     return;
    //   }
    // }

    // // 1. 친구톡 전송전에 공유방지 시 추가해야 함 -> 이 링크로 직접접근시 차단.
    // if (this.$route.path === '/friends/PrografTx_HealingCamp') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/PrografAi_DH_TAC_Connect') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/AstellasBetmiga06') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/AstellasWebinar0716') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/AstellasWebinar03') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/AstellasWebinar0330') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/AstellasBetmigaSympo04') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/AstellasESLA04') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/PrografTx_1st') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/PrografTx_2nd_liver') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/PrografTx_2nd_kidney') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/AstellasWebinar0411') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/HarnaldSympo04') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/PrografAi_KCR2024') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/HarnaldUrology05') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/PrografAi_DH') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/Harnald_News05') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/HarnaldSympo05') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // if (this.$route.path === '/friends/PrografAi_DH_Tacrolimus') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/AstellasWebinar0613') {
    //   this.isKakao = 'Y';
    //   return;
    // }
    // if (this.$route.path === '/friends/PrografTx_3rd') {
    //   this.isKakao = 'Y';
    //   return;
    // }

    // // 공유방지 기능(변경 처리)
    // // 다른채널인지 알수 있는 방법?
    // // 공유방지 카드 추가 방법:
    // // 1. 기존방법대로 카드추가(card링크 밑에)
    // // 2. was(api.controller.js -> bot.id -> utterance -> 퍼미션 기록 정보 추가)에서 퍼미션 정보 저장 기능 추가 해야 공유방지 기능 작동함
    // this.isKakao = 'N';
    // this.getHcpInfo(this.$route.query).then((res) => {
    //   // console.log('getHcpInfo res', res);
    //   if (res === undefined || res === null) return;
    //   // this.isKakao = 'Y';
    //   console.log('getHcpInfo res', res);
    //   // return;
    //   // this.isKakao = res.isHcp;
    //   // this.isKakao = this.userAgent.indexOf('KAKAOTALK') < 0 ? 'N' : 'Y';

    //   if (res.isHcp === 'Y') {
    //     this.isKakao = 'Y';
    //     console.log('인증후 : ', res.mobile4info);
    //     this.mobile = res.mobile4info;
    //     // 추가 첫페이지 저장
    //     setTimeout(() => {
    //       // if (this.$route.path === '/card') this.$refs.refAI_Card._setFirstEvent();
    //       // if (this.$route.path === '/card/harnalD') this.$refs.refAI_HanalDCard._setFirstEvent();
    //       // if (this.$route.path === '/card/betamiga') this.$refs.refAI_Betamiga._setFirstEvent();
    //       // if (this.$route.path === '/card/prografTX') this.$refs.ref_PrografTX._setFirstEvent();
    //       // if (this.$route.path === '/card/prografAI') this.$refs.refAI_PrograAI23._setFirstEvent();
    //       // if (this.$route.path === '/card/prografAI_RA') this.$refs.refAI_RA._setFirstEvent();
    //       // if (this.$route.path === '/card/prografAI_LN') this.$refs.refAI_LN._setFirstEvent();
    //       // if (this.$route.path === '/card/PrografTx_NewsClipping_April')
    //       //   this.$refs.refPrografTx_NewsClipping_April._setFirstEvent();

    //       if (this.$route.path === '/friends/card1') this.$refs.refFriendsCardPageTest01._setFirstEvent();
    //       if (this.$route.path === '/friends/AstellasWebinar03') this.$refs.refAstellasWebinar03._setFirstEvent();
    //       if (this.$route.path === '/friends/AstellasWebinar0330') this.$refs.refAstellasWebinar0330._setFirstEvent();
    //       // if (this.$route.path === '/friends/AstellasBetmigaSympo04');
    //       // if (this.$route.path === '/friends/AstellasESLA04');
    //       // if (this.$route.path === '/friends/PrografTx_1st');
    //       // if (this.$route.path === '/friends/PrografTx_2nd_liver') this.$refs.refPrografTx_2nd_liver._setFirstEvent();
    //       if (this.$route.path === '/friends/AstellasWebinar0411') this.$refs.refAstellasWebinar0411._setFirstEvent();
    //       if (this.$route.path === '/friends/HarnaldSympo04') this.$refs.refHarnaldSympo04._setFirstEvent();
    //       if (this.$route.path === '/friends/PrografAi_KCR2024') this.$refs.refPrografAi_KCR2024._setFirstEvent();
    //       if (this.$route.path === '/friends/HarnaldUrology05') this.$refs.refHarnaldUrology05._setFirstEvent();
    //       if (this.$route.path === '/friends/PrografAi_DH') this.$refs.refPrografAi_DH._setFirstEvent();
    //       if (this.$route.path === '/friends/Harnald_News05') this.$refs.refHarnald_News05._setFirstEvent();
    //       if (this.$route.path === '/friends/HarnaldSympo05') this.$refs.refHarnaldSympo05._setFirstEvent();
    //       if (this.$route.path === '/friends/PrografAi_DH_Tacrolimus')
    //         this.$refs.refPrografAi_DH_Tacrolimus._setFirstEvent();
    //       if (this.$route.path === '/friends/AstellasWebinar0613') this.$refs.refAstellasWebinar0613._setFirstEvent();
    //       if (this.$route.path === '/friends/PrografTx_3rd') this.$refs.refPrografTx_3rd._setFirstEvent();
    //       if (this.$route.path === '/friends/PrografAi_DH_TAC_Connect')
    //         this.$refs.refPrografAi_DH_TAC_Connect._setFirstEvent();
    //       if (this.$route.path === '/friends/PrografTx_HealingCamp')
    //         this.$refs.refPrografTx_HealingCamp._setFirstEvent();
    //       if (this.$route.path === '/friends/AstellasBetmiga06') this.$refs.refAstellasBetmiga06._setFirstEvent();
    //       if (this.$route.path === '/friends/AstellasWebinar0716') this.$refs.refAstellasWebinar0716._setFirstEvent();
    //       // if (this.$route.path === '/card/AstellasBetmigaSympo07')
    //       //   this.$refs.refAstellasBetmigaSympo07._setFirstEvent();
    //       // if (this.$route.path === '/card/Betamiga2407') this.$refs.refBetamiga2407._setFirstEvent();
    //       // if (this.$route.path === '/card/HanalD4207') this.$refs.refHanalD4207._setFirstEvent();
    //     }, 300);
    //   }
    // });
    // 휴대폰 번호로
    // console.log('', this.$route.query.kakaoId);
    // if (this.$route.query.kakaoId === undefined) {
    //   setTimeout(() => {
    //     this.$refs.refConfirmModal._showModal();
    //   }, 500);
    // } else {
    //   this.getHcpInfo(this.$route.query).then((res) => {
    //     if (res === undefined || res === null) return;
    //     console.log('res', res);
    //     // this.isKakao = res.isHcp;
    //     this.isKakao = this.userAgent.indexOf('KAKAOTALK') < 0 ? 'N' : 'Y';
    //     this.isKakao = 'Y';
    //     // if (res.isHcp === 'Y') {
    //     //   this.isKakao = 'Y';
    //     // }
    //   });
    // }
  },
  methods: {
    // 로그 쌓는 경로 변경 처리
    slideChange(data) {
      console.log('slideChange', data);
      // this.content = data.content;
      // this.page = data.page;
      // this.link = data.tLink;
      // this.kakaoChannel = this.$route.query.kakaoChannel;
      // this.kakaoUserId = this.$route.query.kakaoId;
      // this.permissionId = this.$route.query.permissionId;

      // // 친구카드 url
      // if (this.$route.path === '/friends/card1') {
      //   this.kakaoChannel = '하루날베타미가봇';
      //   this.cardNews = data.cardNews;
      //   this.saveFriendsContentLog(this).then(() => {});
      //   return;
      // }
      // if (
      //   this.$route.path === '/friends/AstellasWebinar03' ||
      //   this.$route.path === '/friends/AstellasWebinar0330' ||
      //   this.$route.path === '/friends/AstellasBetmigaSympo04' ||
      //   this.$route.path === '/friends/AstellasWebinar0411' ||
      //   this.$route.path === '/friends/HarnaldSympo04' ||
      //   this.$route.path === '/friends/HarnaldUrology05' ||
      //   this.$route.path === '/friends/Harnald_News05' ||
      //   this.$route.path === '/friends/HarnaldSympo05' ||
      //   this.$route.path === '/friends/AstellasWebinar0613' ||
      //   this.$route.path === '/friends/AstellasBetmiga06' ||
      //   this.$route.path === '/friends/AstellasWebinar0716' ||
      //   this.$route.path === '/card/AstellasBetmigaSympo07' ||
      //   this.$route.path === '/card/Betamiga2407' ||
      //   this.$route.path === '/card/HanalD4207'
      // ) {
      //   this.kakaoChannel = '하루날베타미가봇';
      //   this.cardNews = data.cardNews;
      //   this.saveFriendsContentLog(this).then(() => {});
      //   return;
      // }

      // if (
      //   this.$route.path === '/friends/AstellasESLA04' ||
      //   this.$route.path === '/friends/PrografAi_KCR2024' ||
      //   this.$route.path === '/friends/PrografAi_DH' ||
      //   this.$route.path === '/friends/PrografAi_DH_Tacrolimus' ||
      //   this.$route.path === '/friends/PrografAi_DH_TAC_Connect'
      // ) {
      //   this.kakaoChannel = '프로그랍-Autoimmune봇';
      //   this.cardNews = data.cardNews;
      //   this.saveFriendsContentLog(this).then(() => {});
      //   return;
      // }

      // if (
      //   this.$route.path === '/friends/PrografTx_1st' ||
      //   this.$route.path === '/friends/PrografTx_2nd_kidney' ||
      //   this.$route.path === '/friends/PrografTx_2nd_liver' ||
      //   this.$route.path === '/friends/PrografTx_3rd' ||
      //   this.$route.path === '/friends/PrografTx_HealingCamp'
      // ) {
      //   this.kakaoChannel = 'transplantation봇';
      //   this.cardNews = data.cardNews;
      //   this.saveFriendsContentLog(this).then(() => {});
      //   return;
      // }

      // this.saveViewContentLog(this).then(() => {});
    },
    // cell4_completeInputHandler(e) {
    //   this.alertText = '';
    //   this.isKakao = 'N';
    //   this.kakaoChannel = this.$route.query.kakaoChannel;
    //   // 친구카드일때 kakaoChannel값 강제 설정
    //   if (this.$route.path === '/friends/card1') this.kakaoChannel = '하루날베타미가봇';

    //   this.mobile = e;
    //   this.getHcpInfoByMobile4(this).then((res) => {
    //     if (res === undefined) return;
    //     if (res.length > 0) {
    //       this.isKakao = res[0].isHcp;

    //       // 추가 첫페이지 저장
    //       setTimeout(() => {
    //         if (this.$route.path === '/card') this.$refs.refAI_Card._setFirstEvent();
    //         if (this.$route.path === '/card/harnalD') this.$refs.refAI_HanalDCard._setFirstEvent();
    //         if (this.$route.path === '/card/betamiga') this.$refs.refAI_Betamiga._setFirstEvent();
    //         if (this.$route.path === '/card/prografTX') this.$refs.ref_PrografTX._setFirstEvent();
    //         if (this.$route.path === '/card/prografAI') this.$refs.refAI_PrograAI23._setFirstEvent();
    //         if (this.$route.path === '/card/prografAI_RA') this.$refs.refAI_RA._setFirstEvent();
    //         if (this.$route.path === '/card/prografAI_LN') this.$refs.refAI_LN._setFirstEvent();
    //         if (this.$route.path === '/friends/card1') this.$refs.refFriendsCardPageTest01._setFirstEvent();
    //       }, 300);
    //     } else {
    //       console.log('채널사용자 찾지 못함');
    //       this.alertText = '채널사용자가 아닙니다.';
    //       this.$refs.refConfirmModal._showModal();
    //       return;
    //     }
    //   });
    // },
    ...mapActions(['saveViewContentLog', 'saveFriendsContentLog', 'getHcpInfo', 'getHcpInfoByMobile4']),
  },
  computed: {
    ...mapGetters(['isCertifyCard']),
  },
};
</script>
